import pickBy from 'lodash/pickBy';
import { substituteWithUserVocabulary } from 'components/helpers';
import { UserSurvey, UserProfile } from 'app';
import { byCond, QuestionItem, QuestionSection } from 'components/questions';
import { InheritableSurvey, Survey } from './types';

export function contextualSurvey(survey: InheritableSurvey, user: UserProfile): Survey {
  const substitute = substituteWithUserVocabulary(user);
  return substitute<Survey>(
    {
      ...survey,
      sections: survey.sections.map((section) => {
        const { key, title, type, size, required, scales, options, questions } = section;
        return substitute<QuestionSection>(
          {
            key,
            title,
            questions: questions.map<QuestionItem>((item) =>
              substitute<QuestionItem>(
                {
                  type,
                  size,
                  required,
                  scales,
                  options,
                  ...pickBy(item, (value) => value !== null),
                  key: `${section.key}#${item.key}`,
                } as QuestionItem,
                ['label', 'title', 'content', 'variant'],
              ),
            ),
          },
          ['title'],
        );
      }),
    },
    ['title'],
  );
}

/**
 * Calcuate the progress of the userSurvey.
 * @param survey The survey metadata
 * @param userSurvey The user servey data
 * @returns An array of number in the form of [total, completed]
 */
export function surveyProgress(survey: Survey, userSurvey: UserSurvey) {
  return survey.sections.reduce<[number, number]>(
    (sum, section) => {
      return section.questions.filter(byCond(userSurvey)).reduce<[number, number]>((sum, question) => {
        if (!question.required || question.type === 'paragraph') {
          return sum;
        }
        const [total, completed] = sum;
        return [total + 1, userSurvey[question.key] !== undefined ? completed + 1 : completed];
      }, sum);
    },
    [0, 0],
  );
}
