import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'components';
import { headings } from 'components/dashboard';
import { UserSurvey, UserProfile } from 'app';
import { startSurvey, updateUserSurvey } from 'app/profileSlice';
import { contextualSurvey } from './helpers';
import { SurveyResultView } from './SurveyResultView';
import { SurveyQuestionView } from './SurveyQuestionView';
import { InheritableSurvey } from './types';
import { AppPage } from 'components/layout';
import { useCompletedEffect } from './useCompletedEffect';

export type SurveyEntry = {
  id: string;
  scope: string;
  title: string;
};

export type SurveyContentProps = {
  survey: InheritableSurvey;
  user: UserProfile;
  userSurveys: Record<string, UserSurvey>;
  next: SurveyEntry | null;
  prev: SurveyEntry | null;
};

export function SurveyContent(props: SurveyContentProps & {}) {
  const { user, userSurveys, prev, next } = props;
  const dispatch = useDispatch();
  const survey = React.useMemo(() => contextualSurvey(props.survey, user), [user]);
  useCompletedEffect(survey, user, userSurveys);
  const userSurvey = userSurveys[survey.id];
  const activeSection = userSurvey?._step || 0;

  React.useEffect(() => {
    if (!userSurvey) {
      dispatch(startSurvey(survey.id));
    }
  }, [userSurvey]);

  if (!userSurvey) {
    return null;
  }

  const handleUpdateSurvey = (data: Partial<UserSurvey>) => {
    dispatch(updateUserSurvey(survey!.id, data));
  };

  return (
    <AppPage title={survey.title}>
      <Box mt={2}>
        <Breadcrumbs aria-label="breadcrumb-feedback">
          <Link color="inherit" href="/">
            Home
          </Link>
          {survey.scope === 'baseline' ? (
            <Link color="inherit" href="/dashboard#baseline">
              Dashboard
            </Link>
          ) : (
            <Link color="inherit" href="/dashboard/#followup">
              Dashboard
            </Link>
          )}
          <Typography color="textSecondary">
            {survey.scope === 'baseline' ? headings.survey : headings.followup}
          </Typography>
          <Typography color="textPrimary">{survey.title}</Typography>
        </Breadcrumbs>

        {activeSection! < survey.sections.length ? (
          <SurveyQuestionView
            survey={survey}
            userSurvey={userSurvey}
            updateUserSurvey={handleUpdateSurvey}
            isLast={next === null}
          />
        ) : (
          <SurveyResultView
            user={user}
            survey={survey}
            userSurvey={userSurvey}
            updateUserSurvey={handleUpdateSurvey}
            prev={prev}
            next={next}
          />
        )}
      </Box>
    </AppPage>
  );
}
