import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { UserSurvey } from 'app';
import { surveyProgress } from './helpers';
import { QuestionStepper } from 'components/questions';
import { Survey } from './types';

export type SurveyQuestionViewProps = {
  survey: Survey;
  userSurvey: UserSurvey;
  updateUserSurvey: (data: Partial<UserSurvey>) => void;
  isLast: boolean;
};

export function SurveyQuestionView(props: SurveyQuestionViewProps) {
  const { survey, userSurvey, updateUserSurvey, isLast } = props;
  const step = userSurvey._step || 0;

  const handleChange = (data: Record<string, any>) => {
    const [_total, _completed] = surveyProgress(survey, data as UserSurvey);
    updateUserSurvey({ ...data, _total, _completed });
  };

  const setStep = (step: number) => {
    updateUserSurvey({ _step: step });
  };

  React.useEffect(() => {
    const element = document.querySelector(`[arie-label="${survey.sections[step - 1 >= 0 ? step - 1 : 0].title}"`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }, 250);
    }
  }, [step]);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {survey.title}
      </Typography>

      <QuestionStepper
        step={step}
        setStep={setStep}
        data={userSurvey}
        onChange={handleChange}
        sections={survey.sections}
        finishLabel={isLast ? 'Finish' : 'Submit'}
      />
    </Box>
  );
}
