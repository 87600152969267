import React from 'react';
import { graphql } from 'gatsby';
import { SurveyEntry, SurveyContent, InheritableSurvey } from 'components/survey';
import { useProfile } from 'app';

export type SurveyPageTemplateProps = {
  data: {
    survey: InheritableSurvey;
    allSurvey: {
      nodes: Array<SurveyEntry>;
    };
  };
};

export default function SurveyPageTemplate(props: SurveyPageTemplateProps) {
  const profile = useProfile();
  const {
    survey,
    allSurvey: { nodes: surveyEntries },
  } = props.data;

  if (profile.data === null) {
    return null;
  }

  const { user, surveys: userSurveys } = profile.data;
  const current = surveyEntries.findIndex((entry) => entry.id === survey.id);
  const prev = current > 0 ? surveyEntries[current - 1] : null;
  const next = current < surveyEntries.length - 1 ? surveyEntries[current + 1] : null;

  return <SurveyContent survey={survey} user={user} userSurveys={userSurveys} next={next} prev={prev} />;
}

export const query = graphql`
  query ($id: String!, $scope: String!) {
    allSurvey(filter: { scope: { eq: $scope } }, sort: { fields: id }) {
      nodes {
        id
        scope
        title
      }
    }
    survey(id: { eq: $id }) {
      id
      title
      scope
      sections {
        key
        title
        type
        size
        required
        scales
        options
        questions {
          key
          type
          cond
          scales
          options
          size
          required
          label
          title
          helperText
          inputLabel
          content
          variant
        }
      }
      cover {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
