import React from 'react';
import { useDispatch } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { getSymptons } from 'components/feeback';
import { UserSurvey, UserProfile } from 'app';
import { surveysCompleted } from 'app/helpers';
import { baselineCompleted, followupCompleted } from 'app/profileSlice';
import { Survey } from './types';

const useSurveyGroups = () => {
  const {
    allSurvey: { nodes: surveys },
  } = useStaticQuery<{
    allSurvey: {
      nodes: Array<{ id: string; scope: 'followup' | 'baseline' }>;
    };
  }>(graphql`
    query {
      allSurvey {
        nodes {
          id
          scope
        }
      }
    }
  `);

  return surveys.reduce<[string[], string[]]>(
    ([baseline, followup], survey) => {
      return survey.scope === 'baseline' ? [[...baseline, survey.id], followup] : [baseline, [...followup, survey.id]];
    },
    [[], []],
  );
};

export const useCompletedEffect = (survey: Survey, user: UserProfile, userSurveys: Record<string, UserSurvey>) => {
  const dispatch = useDispatch();
  const [baselineIds, followupIds] = useSurveyGroups();

  React.useEffect(() => {
    const scope = survey.scope;
    const completed = surveysCompleted(scope === 'baseline' ? baselineIds : followupIds, userSurveys);

    if (completed) {
      if (scope === 'baseline' && !user.surveyCompletedAt) {
        const symtons = getSymptons(user, userSurveys['s3-k6'], userSurveys['s4-rcads']);
        dispatch(baselineCompleted(symtons));
      }

      if (scope === 'followup' && !user.followupCompletedAt) {
        const symtons = getSymptons(user, userSurveys['f3-k6'], userSurveys['f4-rcads']);
        dispatch(followupCompleted(symtons));
      }
    }
  }, [user, userSurveys]);
};
